<div class="border-b header dark:border-none bg-black dark:bg-[#323540] min-h-[72px] fixed top-0 w-full z-[1000] flex">
  <div class="dark:border-transparent logo logo-dark transition duration-200 ease-in-out items-center h-[71px]">
    <a class="block text-center">

    </a>
  </div>
  <div class="flex flex-1 nav-wrap md:ps-[20px] md:pe-[30px] ps-[10px] pe-[15px] bg-white dark:bg-[#1b1d2a]">
    <ul class="flex items-center mb-0 list-none nav-left ps-0 gap-x-[14px] gap-y-[9px]">
      <li class="desktop-toggle">
        <a class="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-transparent hover:bg-primary/10 hover:text-primary group transition duration-200 ease-in-out text-[#525768] dark:text-white/60 dark:hover:bg-white/10"
          (click)="toggleFold()">
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&.is-folded>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg" />
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-folded>svg]:block"
            src="assets/images/svg/align-left.svg" />
        </a>
      </li>
      <li class="mobile-toggle">
        <a class="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-transparent hover:bg-primary/10 hover:text-primary group transition duration-200 ease-in-out text-[#525768] dark:text-white/60 dark:hover:bg-white/10"
          (click)="toggleExpand()">
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&.is-expand>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg" />
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-expand>svg]:block"
            src="assets/images/svg/align-left.svg" />
        </a>
      </li>
      <!-- <li>
        <a class="inline-flex items-center bg-normalBG hover:bg-primary/10 hover:text-primary dark:bg-[#282b37] dark:text-white/60 min-h-[34px] max-sm:w-[34px] max-sm:justify-center px-4 max-sm:px-0 rounded-2xl gap-[8px] dark:hover:bg-white/60 group dark:hover:text-dark transition duration-200 capitalize" (click)="quickViewToggle()">
          <i nz-icon nzType="edit" theme="outline"></i>
          <span class="text-sm font-medium text-body group-hover:text-primary dark:text-white/60 dark:group-hover:text-current max-sm:hidden">
            customize...
          </span>
        </a>
        <nz-drawer [nzWidth]="280" nzTitle="Theme Config" [nzClosable]="false" [nzVisible]="quickViewVisible"
          nzPlacement="right" (nzOnClose)="quickViewToggle()">
          <ng-container *nzDrawerContent>
            <app-quick-view></app-quick-view>
          </ng-container>
        </nz-drawer>
      </li> -->
    </ul>
    <!-- Top Menu -->
    <app-menu *ngIf="user && user.type == 'CUSTOMER'"></app-menu>
    <!-- desktop -->
    <div class="items-center hidden ms-auto xl:flex">
      <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
    </div>
    <ng-template #myTemplate>
      <ul
        class="flex items-center justify-end flex-auto mb-0 list-none ps-0 nav-right sm:gap-x-[25px] max-sm:gap-x-[15px] gap-y-[15px]">

        <li>
          <a class="flex items-center relative" nz-dropdown [nzDropdownMenu]="popNotification" nzTrigger="click">
            <!-- Bell Icon with Badge -->
            <nz-badge [nzStyle]="{ backgroundColor: '#FA8B0C' }" nzDot>
              <svg-icon src="assets/images/svg/bell.svg" class="text-3xl"></svg-icon>
            </nz-badge>
          </a>

          <!-- Dropdown Menu -->
          <nz-dropdown-menu #popNotification="nzDropdownMenu">
            <div
              class="bg-white dark:bg-[#1b1e2b] shadow-lg dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] rounded-4 px-4 py-3 md:min-w-[400px] sm:w-[300px] max-sm:w-[230px]">
              <!-- Dropdown Title -->
              <h1
                class="flex items-center justify-between text-sm bg-gray-100 dark:bg-[#32333f] rounded-md py-2 px-4 text-dark dark:text-white font-semibold">
                <span class="title-text">
                  <span
                    class="inline-flex items-center justify-center w-5 h-5 text-xs text-white rounded-full bg-warning">
                    {{ notifications?.length }}
                  </span>
                </span>
                <span class="font-medium text-gray-600 dark:text-white">Notifications</span>
              </h1>

              <!-- Scrollable Notification List -->
              <perfect-scrollbar>
                <ul class="p-0 max-h-[250px] overflow-auto relative space-y-2">
                  <li *ngFor="let notification of notifications" class="w-full">
                    <button
                      class="group relative block w-full px-4 py-3 text-body dark:text-white/80 hover:bg-gray-100 dark:hover:bg-[#32333f] hover:shadow-md dark:shadow-none rounded-md transition-all">
                      <div class="flex items-start gap-[15px] flex-wrap max-xs:flex-col">
                        <!-- Icon Placeholder (could be an icon if needed) -->
                        <div class="w-10 h-10 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                      </div>

                      <!-- Notification Content -->
                      <div class="flex items-center justify-between flex-1">
                        <div class="text-start">
                          <h1
                            class="flex items-center justify-between mb-0.5 text-sm font-normal text-[#5a5f7d] dark:text-white">
                            <span class="text-primary me-1.5 font-medium">
                              {{ notification.titleEn }}
                            </span>
                            {{ notification.contentEn }}
                          </h1>
                          <p class="mb-0 text-xs text-theme-gray dark:text-white/60">
                            {{ notification.createdAt | date: 'dd/MM/yyyy hh:mm a' }}
                          </p>
                        </div>
                        <div></div>
                      </div>
                    </button>
                  </li>
                </ul>
              </perfect-scrollbar>
            </div>
          </nz-dropdown-menu>
        </li>



        <li>
          <!-- Profile Dropdown Trigger -->
          <span class="flex items-center cursor-pointer text-light whitespace-nowrap" nz-dropdown
            [nzDropdownMenu]="profile">
            <!-- User Avatar with Icon -->
            <nz-avatar [nzIcon]="'user'"
              nzSrc="https://img.freepik.com/premium-vector/traditional-arabic-man-head-icon-vector-avatar_55610-6301.jpg"
              class="w-10 h-10 mx-2 rounded-full ring-2 ring-warning dark:ring-white hover:ring-4 transition duration-300"></nz-avatar>

        
          </span>

          <!-- Profile Dropdown Menu -->
          <nz-dropdown-menu #profile="nzDropdownMenu">
            <div
              class="min-w-[310px] max-sm:min-w-full pt-4 px-6 py-4 bg-white dark:bg-[#1b1e2b] shadow-lg dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] rounded-4">
              <!-- Settings Item -->
              <a class="flex items-center mb-1 justify-between text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] hover:bg-gray-200 dark:hover:bg-[#2b2f38] text-light hover:text-primary dark:hover:text-white/60 dark:text-white/[.87] py-3 px-4 rounded-lg transition duration-300"
                href="/settings">
                <i class="fa fa-cogs text-xl mr-2 text-gray-500 dark:text-gray-400"></i>
                <span>Settings</span>
              </a>

              <!-- Profile Item -->
              <a class="flex items-center mb-1 justify-between text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] hover:bg-gray-200 dark:hover:bg-[#2b2f38] text-light hover:text-primary dark:hover:text-white/60 dark:text-white/[.87] py-3 px-4 rounded-lg transition duration-300"
                href="/profile">
                <i class="fa fa-user-circle text-xl mr-2 text-gray-500 dark:text-gray-400"></i>
                <span>Profile</span>
              </a>

              <!-- Logout Item -->
              <a class="flex items-center justify-between text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] hover:bg-gray-200 dark:hover:bg-[#2b2f38] text-light hover:text-primary dark:hover:text-white/60 dark:text-white/[.87] py-3 px-4 rounded-lg transition duration-300"
                (click)="signOut()">
                <i class="fa fa-sign-out-alt text-xl mr-2 text-gray-500 dark:text-gray-400"></i>
                <span>Logout</span>
              </a>
            </div>
          </nz-dropdown-menu>
        </li>


      </ul>
    </ng-template>
    <!-- desktop -->
    <!-- Mobile -->
    <a class="items-center hidden max-xl:flex text-light text-[18px] dark:text-white/[.87] ms-auto" nz-dropdown
      nzTrigger="click" [nzDropdownMenu]="menu">
      <span nz-icon nzType="more" nzTheme="outline"></span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="bg-white dark:bg-[#1b1c29] px-[20px] py-[6px] rounded-6 hidden max-xl:block">
        <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
      </div>
    </nz-dropdown-menu>
    <!-- Mobile -->
  </div>
</div>