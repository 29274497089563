<div class="common-layout"
    [ngClass]="{
        'is-folded': isFolded,
        'is-expand' : isExpand
    }">
    <app-header></app-header>
    <app-sidenav></app-sidenav>
    <div class="[&.left-space-zero]:ps-0 bg-gray-50 dark:bg-[#010312] ps-[280px] min-h-[100vh] transition-all duration-200 ease-out page-container">
        <div class="mx-[30px] sm:pt-[calc(70px+30px)] max-sm:pt-[calc(70px+25px)] min-h-[calc(100vh-65px)]">
            <div class="flex max-sm:flex-col flex-wrap justify-between items-center gap-x-[30px] gap-y-[15px] bg-white shadow-sm mb-[23px] p-4 rounded-xl leading-[1.8571428571]" *ngIf="contentHeaderDisplay !== 'none'">
                <h4 class="font-semibold text-[20px] text-dark dark:text-white/[.87] capitalize" *ngIf="breadcrumbs$ | async; let breadcrumbs"  [translate]="(breadcrumbs[breadcrumbs.length - 1].label)"></h4>
                <nz-breadcrumb class="flex items-center modify-breadcrumb" [nzSeparator]="iconTemplate">
                    <i class="me-[5px]" nz-icon nzType="home" routerLink="/dashboard"></i>
                    <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs$ | async">
                        <a class="font-normal text-[14px] capitalize" 
                        [translate]="breadcrumb.label"
                        >
                          
                        </a>
                    </nz-breadcrumb-item>
                    <ng-template #iconTemplate><span class="inline-flex -top-0.5 relative bg-light-extra rounded-full w-1 h-1"></span></ng-template>
                </nz-breadcrumb>
            </div>
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
   
</div>
