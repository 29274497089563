<div class="top-0 z-[1000] fixed flex bg-black dark:bg-[#323540] border-b dark:border-none w-full min-h-[72px] header">
  <div class="items-center dark:border-transparent h-[71px] transition duration-200 ease-in-out logo logo-dark">
    <a class="block text-center">

    </a>
  </div>
  <div class="flex flex-1 bg-white dark:bg-[#1b1d2a] ps-[10px] md:ps-[20px] pe-[15px] md:pe-[30px] nav-wrap">
    <ul class="nav-left flex items-center gap-x-[14px] gap-y-[9px] mb-0 ps-0 list-none">
      <li class="desktop-toggle">
        <a class="group flex justify-center items-center bg-transparent hover:bg-primary/10 dark:hover:bg-white/10 rounded-full w-[40px] h-[40px] text-[#525768] hover:text-primary dark:text-white/60 transition duration-200 ease-in-out"
          (click)="toggleFold()">
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&.is-folded>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg" />
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-folded>svg]:block"
            src="assets/images/svg/align-left.svg" />
        </a>
      </li>
      <li class="mobile-toggle">
        <a class="group flex justify-center items-center bg-transparent hover:bg-primary/10 dark:hover:bg-white/10 rounded-full w-[40px] h-[40px] text-[#525768] hover:text-primary dark:text-white/60 transition duration-200 ease-in-out"
          (click)="toggleExpand()">
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&.is-expand>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg" />
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-expand>svg]:block"
            src="assets/images/svg/align-left.svg" />
        </a>
      </li>
      <!-- <li>
        <a class="group inline-flex max-sm:justify-center items-center gap-[8px] bg-normalBG hover:bg-primary/10 dark:hover:bg-white/60 dark:bg-[#282b37] px-4 max-sm:px-0 rounded-2xl max-sm:w-[34px] min-h-[34px] hover:text-primary dark:hover:text-dark dark:text-white/60 capitalize transition duration-200" (click)="quickViewToggle()">
          <i nz-icon nzType="edit" theme="outline"></i>
          <span class="group-hover:text-primary dark:group-hover:text-current max-sm:hidden font-medium text-body dark:text-white/60 text-sm">
            customize...
          </span>
        </a>
        <nz-drawer [nzWidth]="280" nzTitle="Theme Config" [nzClosable]="false" [nzVisible]="quickViewVisible"
          nzPlacement="right" (nzOnClose)="quickViewToggle()">
          <ng-container *nzDrawerContent>
            <app-quick-view></app-quick-view>
          </ng-container>
        </nz-drawer>
      </li> -->
    </ul>
    <!-- Top Menu -->
    <app-menu *ngIf="user && user.type == 'CUSTOMER'"></app-menu>
    <!-- desktop -->
    <div class="hidden xl:flex items-center ms-auto">
      <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
    </div>
    <ng-template #myTemplate>
      <ul
        class="nav-right flex flex-auto justify-end items-center gap-y-[15px] sm:gap-x-[25px] max-sm:gap-x-[15px] mb-0 ps-0 list-none">

        <li>
          <a class="relative flex items-center"  nzTrigger="click" routerLink="/v1/notifications">
            <!-- Bell Icon with Badge -->
            <nz-badge [nzStyle]="{ backgroundColor: '#FA8B0C' }" nzDot>
              <svg-icon src="assets/images/svg/bell.svg" class="text-3xl"></svg-icon>
            </nz-badge>
          </a>

      
        </li>



        <li>
          <!-- Profile Dropdown Trigger -->
          <span class="flex items-center text-light whitespace-nowrap cursor-pointer" nz-dropdown
            [nzDropdownMenu]="profile">
            <!-- User Avatar with Icon -->
            <nz-avatar [nzIcon]="'user'"
              nzSrc="https://img.freepik.com/premium-vector/traditional-arabic-man-head-icon-vector-avatar_55610-6301.jpg"
              class="mx-2 rounded-full ring-2 ring-warning hover:ring-4 dark:ring-white w-10 h-10 transition duration-300"></nz-avatar>

        
          </span>

          <!-- Profile Dropdown Menu -->
          <nz-dropdown-menu #profile="nzDropdownMenu">
            <div
              class="bg-white dark:bg-[#1b1e2b] shadow-lg dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] px-6 py-4 pt-4 rounded-4 min-w-[310px] max-sm:min-w-full">
              <!-- Settings Item -->
            

              <!-- Logout Item -->
              <a class="flex justify-between items-center bg-[#f4f5f7] hover:bg-gray-200 dark:hover:bg-[#2b2f38] dark:bg-[#32333f] px-4 py-3 rounded-lg font-medium text-light hover:text-yellow-600 dark:hover:text-white/60 dark:text-white/[.87] text-sm transition duration-300"
                (click)="signOut()">
                <i class="mr-2 text-gray-500 dark:text-gray-400 text-xl fa fa-sign-out-alt"></i>
                <span>Logout</span>
              </a>
            </div>
          </nz-dropdown-menu>
        </li>


      </ul>
    </ng-template>
    <!-- desktop -->
    <!-- Mobile -->
    <a class="hidden max-xl:flex items-center ms-auto text-[18px] text-light dark:text-white/[.87]" nz-dropdown
      nzTrigger="click" [nzDropdownMenu]="menu">
      <span nz-icon nzType="more" nzTheme="outline"></span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="hidden max-xl:block bg-white dark:bg-[#1b1c29] px-[20px] py-[6px] rounded-6">
        <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
      </div>
    </nz-dropdown-menu>
    <!-- Mobile -->
  </div>
</div>