import { SideNavInterface } from "../../interfaces/side-nav.type";

export const ROUTES_CUSTOMER_DASHBOARD = [
 
 
 
 

 
 
 
 
  {
    path: "/dashboard",
    title: "Dashboard",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dashboard",
    submenu: [],
  },

  {
    path: "/v1/lock-panel",
    title: "Lock panel",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "lock",
    submenu: [],
  },
  
 {
    path: "/v1/Administration",
    title: "Administration",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "user",
    submenu: [],
  },

  {
    path: "/v1/customer-list",
    title: "Customers",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "user",
    submenu: [],
  },

  {
    path: "/v1/parking-list",
    title: "Parkings",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "car",
    submenu: [],
  },
 

  {
    path: "/v1/pack-list",
    title: "Parking packages",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dollar-circle",
    submenu: [],
  },
  {
    path: "/v1/notification",
    title: "Notification",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "bell",
    submenu: [],
  },

  {
    path: "/v1/reservations",
    title: "Reservations",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "calendar",
    submenu: [],
  },

  {
    path: "/v1/pack-purchases",
    title: "Package purchases",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "shopping-cart",
    submenu: [],
  },

  {
    title: "Financial",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "credit-card",
    submenu: [
      {
        path: "/v1/Deposits",
        title: "Deposits",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "wallet",
        submenu: [],
      },
      {
        path: "/v1/income-list",
        title: "Incomes",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "wallet",
        submenu: [],
      },
      {
        path: "/v1/payouts",
        title: "Payouts",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "wallet",
        submenu: [],
      },
      {
        path: "/v1/provider-list",
        title: "Providers",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "user",
        submenu: [],
      },
    ],
  },



  {
    path: "/v1/support",
    title: "Help and support",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "issues-close",
    submenu: [],
  },

  {
    path: "/v1/slot-types",
    title: "Slot types",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting",
    submenu: [],
  },
/**
 *  {
    path: "/v1/vehicle-types",
    title: "Vehicle types",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },
 */
 

  {
    path: "/v1/platform-setting",
    title: "platform Settings",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting",
    submenu: [],
  },
 
 
 
  {
    path: "/v1/notificationType-list",
    title: "Notification Type",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "bell",
    submenu: [],
  },

  {
    path: "/v1/facilities",
    title: "Facilities",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting",
    submenu: [],
  },

];
