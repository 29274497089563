import { SideNavInterface } from "../../interfaces/side-nav.type";

export const ROUTES_CUSTOMER_DASHBOARD = [
  {
    path: "/dashboard",
    title: "Dashboard",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "appstore", // Improved: Represents an overview/dashboard
    submenu: [],
  },
  {
    path: "/v1/lock-panel",
    title: "Lock Panel",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "lock", // Represents security or locking
    submenu: [],
  },
  {
    title: "Administration",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting", // Represents general administration or settings
    submenu: [
      {
        path: "/v1/admin-list",
        title: "Administrators",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "usergroup-add", // Represents adding or managing a group of users
        submenu: []
      },
      {
        path: "/v1/admin-roles",
        title: "Administration Roles",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "audit", // Represents roles, permissions, or audits,
        submenu: []
      },
    ],
  },
  
  {
    path: "/v1/customer-list",
    title: "Customers",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "idcard", // Improved: Represents user/customer profiles
    submenu: [],
  },
  {
    path: "/v1/parking-list",
    title: "Parkings",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "car", // Remains relevant for parking management
    submenu: [],
  },
  {
    path: "/v1/pack-list",
    title: "Parking Packages",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "gift", // Improved: Represents packages or offers
    submenu: [],
  },
  {
    path: "/v1/notification",
    title: "Notification",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "notification", // Improved: Represents notification alerts
    submenu: [],
  },
  {
    path: "/v1/reservations",
    title: "Reservations",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "calendar", // Remains relevant for booking/reservations
    submenu: [],
  },
  {
    path: "/v1/pack-purchases",
    title: "Package Purchases",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "shopping-cart", // Represents e-commerce or purchases
    submenu: [],
  },
  {
    title: "Financial",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dollar", // Improved: Represents financial sections
    submenu: [
      {
        path: "/v1/Deposits",
        title: "Deposits",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "bank", // Improved: Represents monetary deposits
        submenu: [],
      },
      {
        path: "/v1/income-list",
        title: "Incomes",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "rise", // Improved: Represents financial income/growth
        submenu: [],
      },
      {
        path: "/v1/payout-list",
        title: "Payouts",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "dollar-circle", // Remains relevant for payouts
        submenu: [],
      },
      {
        path: "/v1/payout-accounts",
        title: "Payout Accounts",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "bank", // Remains relevant for payouts
        submenu: [],
      },
      {
        path: "/v1/provider-list",
        title: "Providers",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "solution", // Improved: Represents service providers
        submenu: [],
      },
    ],
  },
  {
    path: "/v1/support",
    title: "Help and Support",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "question-circle", // Improved: Represents help and support
    submenu: [],
  },
  {
    path: "/v1/slot-types",
    title: "Slot Types",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "appstore", // Improved: Represents categorized slot types
    submenu: [],
  },
  {
    path: "/v1/vehicle-types",
    title: "Vehicle Types",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "appstore", // Improved: Represents categorized slot types
    submenu: [],
  },
  

  {
    path: "/v1/platform-setting",
    title: "Platform Settings",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting", // Remains relevant for settings
    submenu: [],
  },
  {
    path: "/v1/notificationType-list",
    title: "Notification Type",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "bell", // Improved: Specific to notifications
    submenu: [],
  },
  {
    path: "/v1/facilities",
    title: "Facilities",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "build", // Improved: Represents building-related facilities
    submenu: [],
  },
];

